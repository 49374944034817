import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {RoadmapComponent} from './pages/roadmap/roadmap.component';
import {CommunityComponent} from './pages/community/community.component';
import {LearnComponent} from './pages/learn/learn.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {TermsComponent} from './pages/terms/terms.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'roadmap', component: RoadmapComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'community', component: CommunityComponent },
  { path: 'learn', component: LearnComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
