import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  public form: FormGroup;

  constructor(private toast: MatSnackBar) {
    this.form = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
    });
  }

  get email() {
    return this.form.get('email');
  }

  ngOnInit() {

  }

  registerInterest() {

    // this.apiService.submitFeedback(this.form.get('email').value).then((data) => {
    //   // console.log(data);
    //   this.form.reset();
    //   this.toast.open('Thank you for registering your interest')._dismissAfter(6000);
    // });

  }

  public getErrorMessage(field: string) {
    return 'Please enter a valid email address';
  }
}
