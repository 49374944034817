<app-header></app-header>
<div class="container p-4">
  <div class="row mb-5">
    <div class="col-sm mt-4">
      <h2>Community</h2>
      <p>Join our community of teachers by following us on twitter and facebook</p>
      <a class="btn btn-primary mr-2" href="https://www.facebook.com/daybookonline/" target="_blank">Facebook</a>
      <a class="btn btn-primary" href="https://twitter.com/DaybookSupport/" target="_blank">Twitter</a>
    </div>
    <div class="col-sm mt-4">
      <h2>Contact Us</h2>
      <p>You can contact us anytime with the email address below</p>
      <div *ngIf="!showEmail"><button class="btn btn-primary mr-2" (click)="reveal()">Click here to reveal our email address</button></div>
      <div class="alert alert-secondary" *ngIf="showEmail"><b>email</b>: <a href="mailto:{{ contactEmail }}"> {{ contactEmail }}</a></div>
    </div>
  </div>
</div>
<div style="background-color: #eeeeee;">
  <div class="container p-4">
    <h2>FAQ</h2>

    <div class="row mt-4">
      <div class="col-sm">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">The login details from my old Daybook are not working in the new Daybook. What should I do?</h5>
            <p class="card-text">You will need to create a new account to use the new daybook. Just click on the 'register' button on the login page.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Will exporting be available in the new Diary?</h5>
            <p class="card-text">Absolutely. Exporting features will be made available soon.</p>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="row mt-4">-->
<!--      <div class="col-sm">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title">Will exporting be available in the new Diary?</h5>-->
<!--            <p class="card-text">Absolutely. Exporting features will be made available soon.</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="row">-->
<!--      <div class="col-sm mt-4">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title">Can I export classes or grade/roll data?</h5>-->
<!--            <p class="card-text">Yes! There are many ways to export data from Daybook. </p>-->
<!--            <p class="card-text">To export data from a specfic class, go to:<br> <b>Classes</b> tab > <b>Class Name</b> > <b>Exporting</b></p>-->
<!--            <p class="card-text">To export data from your whole diary, go to:<br> <b>More</b> tab > <b>Export Daybook</b> > <b>Export</b></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-sm mt-4">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title">What if I have multiple classes?</h5>-->
<!--            <p class="card-text">Assuming that you have more than one class, you would enter ‘Classes’ much like a secondary teacher would. </p>-->
<!--            <p class="card-text">The basic set up would involve you creating more than one class in the set up wizard and entering that class into your timetable. You may have this class for many different lessons e.g. Math, Science, so you would set things up similarly to having only one class.</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row mt-4">-->
<!--      <div class="col-sm">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title">How do I set things up if I only have one class?</h5>-->
<!--            <p class="card-text">As you can see, Day Book is really just a blocking data base. Each lesson is just one block in your weekly/two weeks or your schedule.</p>-->
<!--            <p class="card-text">Hints:</p>-->
<!--            <ul class="card-text">-->
<!--              <li>Once you’ve entered Term/Semester dates and your class (assuming you only have 1 class), you’re prompted to enter your timetable (schedule) (image of daily schecule here)</li>-->
<!--              <li>This is a two-fold process: ‘Edit Daily Schedule’ and your weekly/two weekly timetable. This may seem confusing, but the ‘Edit Daily Schedule’ is like giving your calendar a format e.g. each day has 5 blocks, 2 breaks etc.. Set this up so that you have sufficent blocks per day, with the option of choosing the time of each block. Note: if you have a unique day, add that into this proforma, but keep in mind that you may only use this block once in your schedule and the rest of the week it is left blank.</li>-->
<!--              <li>The second task is to now enter your lessons. Given that you only have one class, each lesson needs to have this class selected. However, instead of entering a room e.g. Hall, you could enter what you want that lesson to be e.g. Music or Dance etc.</li>-->
<!--              <li>This way you can select the one class but have your various lesson transitions.</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

