import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-stay-informed-dialog',
  templateUrl: './stay-informed-dialog.component.html',
  styleUrls: ['./stay-informed-dialog.component.scss']
})
export class StayInformedDialogComponent implements OnInit {

  formGroup: FormGroup;

  constructor(
    private firebase: AngularFirestore,
    private snack: MatSnackBar,
    private dialogRef: MatDialogRef<StayInformedDialogComponent>
  ) {
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.email, Validators.required
      ])
    });
  }

  ngOnInit() {
  }

  async submit() {
    if (this.formGroup.valid) {
      await this.firebase.collection('sites/daybooks/forms/stay-informed/submissions').add({
        ...this.formGroup.value
      });
      this.dialogRef.close();
      await this.snack.open('Submitted')._dismissAfter(5);

    }
  }

}
