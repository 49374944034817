<app-header>
  <div class="p-2">
    <div class="container p-4 featured-area">
      <h1>Tutorials</h1>
      <!--      <p class="mt-4" style="color: #ffffffaa;"><i>We are currently re-doing our video tutorials.</i></p>-->
    </div>
  </div>
</app-header>
<div style="min-height: 600px; background-color: #eeeeee;">
  <div _ngcontent-serverapp-c46="" class="container p-4"><h3 _ngcontent-serverapp-c46="">Getting Started</h3>
    <p _ngcontent-serverapp-c46="">First, sign up for an account by going to <a _ngcontent-serverapp-c46=""
                                                                                href="http://app.daybook.com.au">http://app.daybook.com.au</a>
      or by downloading the app from the app store.</p>
    <p _ngcontent-serverapp-c46="">Then, watch the getting started guide below to setup your diary.</p>
    <div _ngcontent-serverapp-c46="" style="display: flex; flex-direction: row;">
      <div _ngcontent-serverapp-c46="" style="padding-right: 10px;"><h4 _ngcontent-serverapp-c46="">Primary School
        Getting Started Guide</h4>
        <iframe _ngcontent-serverapp-c46="" width="560" height="315" src="https://www.youtube.com/embed/sgisgen0bII"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""></iframe>
      </div>
      <div _ngcontent-serverapp-c46=""><h4 _ngcontent-serverapp-c46="">High School Getting Started Guide</h4>
        <iframe _ngcontent-serverapp-c46="" width="560" height="315" src="https://www.youtube.com/embed/f0Ae0rWyhyI"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""></iframe>
      </div>
    </div>
    <br _ngcontent-serverapp-c46="">
    <mat-divider _ngcontent-serverapp-c46="" role="separator" class="mat-divider mat-divider-horizontal"
                 aria-orientation="horizontal"></mat-divider>
    <br _ngcontent-serverapp-c46=""><br _ngcontent-serverapp-c46="">
    <h3 _ngcontent-serverapp-c46="">Exporting</h3>
    <p _ngcontent-serverapp-c46="">You can create an export from either the Day View or the Lesson view. In either case
      the steps are the same:</p><b _ngcontent-serverapp-c46="">Export a Day</b>
    <ol _ngcontent-serverapp-c46="">
      <li _ngcontent-serverapp-c46="">Navigate to the day that you wish to export/li&gt;</li>
      <li _ngcontent-serverapp-c46="">Click on the 'Export' button in the top right hand corner of the screen and wait
        for the export to complete
      </li>
      <li _ngcontent-serverapp-c46="">Click on the download button to download as a PDF</li>
    </ol>
    <b _ngcontent-serverapp-c46="">Export all your lessons for a particular course</b>
    <ol _ngcontent-serverapp-c46="">
      <li _ngcontent-serverapp-c46="">Navigate to the lessons view and select the course that you wish to export</li>
      <li _ngcontent-serverapp-c46="">Click on the 'Export' button in the top right hand corner of the screen</li>
      <li _ngcontent-serverapp-c46="">Select a date range for your export. (By default it will export the entire Term)
      </li>
      <li _ngcontent-serverapp-c46="">Click 'Export' and wait for the export to complete</li>
      <li _ngcontent-serverapp-c46="">Click on the download button to download as a PDF</li>
    </ol>
    <iframe _ngcontent-serverapp-c46="" width="560" height="315" src="https://www.youtube.com/embed/nfi2H3-fy20"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""></iframe>
    <br _ngcontent-serverapp-c46="">
    <mat-divider _ngcontent-serverapp-c46="" role="separator" class="mat-divider mat-divider-horizontal"
                 aria-orientation="horizontal"></mat-divider>
    <br _ngcontent-serverapp-c46=""><br _ngcontent-serverapp-c46="">
    <h3 _ngcontent-serverapp-c46="">Editing a day</h3>
    <ol _ngcontent-serverapp-c46="">
      <li _ngcontent-serverapp-c46="">Navigate to the day you wish to edit.</li>
      <li _ngcontent-serverapp-c46="">Scroll all the way to the bottom and click on 'edit'</li>
      <li _ngcontent-serverapp-c46="">This will enter 'edit mode'.</li>
    </ol>
    <p _ngcontent-serverapp-c46="">From here you can: </p>
    <ul _ngcontent-serverapp-c46="">
      <li _ngcontent-serverapp-c46="">Drag and drop to rearrange your day</li>
      <li _ngcontent-serverapp-c46="">Click on the pencil icon to edit a period</li>
      <li _ngcontent-serverapp-c46="">Click on the trash icon to delete a period</li>
      <li _ngcontent-serverapp-c46="">Click on the '+' icon at the bottom to add a new period</li>
    </ul>
    <iframe _ngcontent-serverapp-c46="" width="560" height="315" src="https://www.youtube.com/embed/Gv_brCPUmNw"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""></iframe>
  </div>

</div>
