import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule } from './material-module/material-module.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommunityComponent } from './pages/community/community.component';
import { HelpComponent } from './pages/help/help.component';
import { LearnComponent } from './pages/learn/learn.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {StayInformedDialogComponent} from './dialogs/stay-informed-dialog/stay-informed-dialog.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {PricingComponent} from './pages/pricing/pricing.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {TermsComponent} from './pages/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RoadmapComponent,
    TopNavigationComponent,
    HeaderComponent,
    FooterComponent,
    CommunityComponent,
    HelpComponent,
    LearnComponent,
    StayInformedDialogComponent,
    PricingComponent,
    PrivacyComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MaterialModuleModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [],
  entryComponents: [StayInformedDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
