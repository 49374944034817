<app-header>
  <div class="container p-4">
    <div class="row">
      <div class="col-sm">
        <h1>Daybook</h1>
        <h2>the Teachers Daily Planner</h2>
        <p>Daybook is a digital daily planner and teacher's diary designed for Primary School and High School Teachers. It works just like a paper diary, but with all the benefits of a digital platform.</p>
        <p>Now Available in Australia, Canada, New Zeeland, UK & USA</p>
        <a target="_blank" href="https://app.daybook.com.au/auth/register"><div class="btn btn-light">Get Started</div></a>
        <br /><br />
      </div>
      <div class="col-sm">
        <div class="image-container m-4">
          <img src="assets/Tablet.png" width="75%">
        </div>
      </div>
    </div>
  </div>
</app-header>

<div>
  <div class="container p-4">
    <h2>Download from the app store</h2>
    <!--    <div class="alert alert-info mb-4">Google Play is coming soon. In the meantime you can still access Daybook on all devices via your web browser.</div>-->
    <a target="_blank" href="https://apps.apple.com/au/app/daybook-teacher-planner/id1542451662"><img src="assets/download-on-the-app-store-apple.svg" height="45px" /></a>
    <a target="_blank" href="https://play.google.com/store/apps/details?id=au.com.daybook.app"><img src="assets/google-play-badge.png" height="67px" /></a>
  </div>
</div>

<div>
  <div class="container p-4">
    <h3>What is Daybook?</h3>
    <p>Daybook is a digital daily planner and teacher's diary designed for Primary School and High School Teachers. It works just like a paper diary, but with all the benefits of a digital platform.</p>
    <ul>
      <li>Auto fill your daily planner based on your timetable</li>
      <li>Plan your day in the diary, with basic formatting options and Apple Pencil support.</li>
      <li>Plan ahead with the lessons view - see all your lessons for a particular subject / topic in one view</li>
      <li>Save your lessons as a template, and then re-use for other class or next year!</li>
      <li>View your diary wherever and whenever with online/offline support.</li>
      <li>Export your lessons to send to a colleague or keep for your records</li>
      <li>Add to do's and reminders</li>
    </ul>
  </div>
</div>

<div>
  <div class="container p-4">
    <h3>Pricing</h3>
    <p>Daybook is $1.99 (AUD) / Month</p>
    <p>You can trial Daybook for 14 days</p>
  </div>
</div>

<div>
  <div class="container p-4">

    <h3 style="text-align: center">What our customers say</h3>

    <div class="embedsocial-reviews" data-ref="37669aa4a32fc31b96ecba4ff4c25198affaf8fa"> <a class="feed-powered-by-es" href="https://embedsocial.com/products/reviews/" target="_blank" title="Powered by EmbedSocial"> Powered by EmbedSocial<span></span> </a> </div>

<!--    <div class="row">-->
<!--      <div class="jumbotron col-sm m-2">-->
<!--        <h2>I think your product is great! Well done! It is just like my paper copy but more useful.</h2>-->
<!--        <p><b> - Gretchen</b></p>-->
<!--      </div>-->
<!--      <div class="jumbotron col-sm m-2" >-->
<!--        <h2>I used daybook last year and it was great! I am still using it and enjoying it.</h2>-->
<!--        <p><b> - Hayley Kruger</b></p>-->
<!--      </div>-->
<!--      <div class="jumbotron col-sm m-2">-->
<!--        <h2>I've been teaching for over 30 years and your APP is the closest that I've found to a daybook.</h2>-->
<!--        <p><b> - Thekla</b></p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
<div>
</div>
<!--<div>-->
<!--  <div class="container p-4">-->
<!--    <h2>Download from the app store</h2>-->
<!--&lt;!&ndash;    <div class="alert alert-info mb-4">Google Play is coming soon. In the meantime you can still access Daybook on all devices via your web browser.</div>&ndash;&gt;-->
<!--    <a target="_blank" href="https://apps.apple.com/au/app/daybook-teacher-planner/id1542451662"><img src="assets/download-on-the-app-store-apple.svg" height="45px" /></a>-->
<!--    <a target="_blank" href="https://play.google.com/store/apps/details?id=au.com.daybook.app"><img src="assets/google-play-badge.png" height="67px" /></a>-->
<!--  </div>-->
<!--</div>-->
<!--<div>-->
<!--  <div class="container p-4">-->
<!--    <h2>Student Diaries</h2>-->
<!--    <a routerLink="/community"><div class="btn btn-primary" >Contact Us</div></a>-->
<!--  </div>-->
<!--</div>-->

