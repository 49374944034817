<app-header>
  <div class="p-2">
    <div class="container p-4 featured-area">
      <h1>Pricing</h1>
    </div>
  </div>
</app-header>
<div style="min-height: 600px; background-color: #eeeeee;">
  <div class="container p-4">

    <div style="display: flex; flex-direction: row">
      <mat-card class="clearfix">
        <h2>Free Forever</h2>
        <p>(available now)</p>
        <ul>
          <li>Teacher's Diary</li>
          <li>Timetable</li>
          <li>Lesson Planner</li>
          <li>Basic Exporting / Archiving</li>
          <li>Support for Primary & Secondary School Teachers</li>
          <li>More coming in 2021</li>
        </ul>
      </mat-card>
      <mat-card class="clearfix">
        <h2>Professional*</h2>
        <p>(coming Early 2021)</p>
        <ul>
          <li>Everything in the free plan plus...</li>
          <li>Advanced Exporting</li>
          <li>Unlimitted Archives</li>
          <li>Premium Support</li>
          <li>More coming in 2021</li>
        </ul>
      </mat-card>

    </div>
    <br>
    <p>* Customers who currently have a subscription with daybook will be able to remain on their current plan.</p>
  </div>
</div>
