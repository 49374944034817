<div class="container p-4">
  <img src="assets/logo.png" height="85px" width="85px" style="float:left;" />
  <nav class="mt-4">
    <ul class="nav justify-content-end">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/home" routerLinkActive="active">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/roadmap" routerLinkActive="active">Roadmap</a>
      </li>
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/pricing" routerLinkActive="active">Pricing</a>-->
<!--      </li>-->
      <li class="nav-item">
        <a class="nav-link" routerLink="/community" routerLinkActive="active">Community & Support</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/learn" routerLinkActive="active">Tutorials</a>
      </li>
      <!--        <li class="nav-item">-->
      <!--          <a class="nav-link" href="#">Developer API</a>-->
      <!--        </li>-->
      <!--        <li class="nav-item">-->
      <!--          <a class="nav-link" href="#">Support</a>-->
      <!--        </li>-->
      <li class="nav-item">
        <a class="m-4" href="https://app.daybook.com.au/auth/register" target="_blank"><div class="btn btn-light" >Get Started</div></a>
      </li>
      <li class="nav-item">
        <a class="" href="https://app.daybook.com.au/auth/login" target="_blank"><div class="btn btn-light" >Login</div></a>
      </li>
    </ul>
  </nav>
  <div style="clear: both;"></div>
</div>
