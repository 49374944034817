import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  public contactEmail = 'support@daybook.com.au';
  public showEmail = false;

  constructor() { }

  ngOnInit() {
    this.showEmail = false;
  }

  reveal() {
    this.showEmail = true;
  }
}
