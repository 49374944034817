<app-header>
  <div class="p-2">
    <div class="container p-4 featured-area">
      <h1>We've heard your feedback...</h1>
      <h2>... and we are listening!</h2>
      <p class="mt-4" style="color: #ffffffaa;"><i>[Watch this space for our 2022 roadmap!]</i></p>
    </div>
  </div>
</app-header>
<div style="min-height: 600px; background-color: #eeeeee;">
  <div class="container p-4">
<!--    <h1>Be the first to know more!</h1>-->
    <mat-card class="clearfix">
      <h3>Find out more</h3>
<!--      <mat-form-field style="width: 100%">-->
<!--        <input  matInput type="email" placeholder="Enter your email address here" />-->
<!--      </mat-form-field>-->
      <form [formGroup]="form" style="width: 100%">
        <mat-form-field style="width: 100%">
          <input matInput id="email" formControlName="email" type="text" value="" placeholder="Enter your email address here"/>
          <mat-error *ngIf="email?.invalid">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
      </form>
<!--      <div class="form-group">-->
<!--        <input class="form-control" placeholder="Enter your email address here" />-->
<!--      </div>-->
      <button class="btn btn-primary float-right" (click)="registerInterest()">Register your interest</button>
    </mat-card>
  </div>
</div>
